import Vue from 'vue'
import App from './App.vue'
import router from './router'

import Vant from 'vant';
import {Lazyload} from 'vant';

import 'vant/lib/index.css';
import './assets/reset.css';

Vue.use(Vant);
Vue.use(Lazyload);

Vue.config.productionTip = false

new Vue({
    el: '#app',
    router,
    render: h => h(App)
}).$mount('#app')
