import Vue from 'vue'
import Router from 'vue-router'

// 解决多次点击重复路由报错
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

Vue.use(Router)

const router = new Router({
    scrollBehavior: () => ({y: 0}),
	mode: "history",
    routes: [
        {
            // 商品详情
            path: '/article/:id',
            name: 'article',
            component: () => import("@/views/article")
        },
        {
            // 商品详情
            path: '/protocol',
            name: 'protocol',
            component: () => import("@/views/protocol")
        },
        {
            // 注销账号
            path: '/cancel',
            name: 'cancel',
            component: () => import("@/views/cancel")
        }
    ]
})

export default router
